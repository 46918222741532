import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../../pages/home/Home";
import Subs from "../../pages/secret";
import DriverRegistration from "../../pages/driverRegistration";

function AppRouter() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apply" element={<Subs />} />
          <Route path="/driver-registration" element={<DriverRegistration />} />
        </Routes>
      </div>
    </Router>
  );
}

export default AppRouter;
