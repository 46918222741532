import AppRouter from "./components/appRouter";
import AuthContextProvider from "./services/context/AuthContext";

function App() {
  return (
    <AuthContextProvider>
      <div>
        <AppRouter />
      </div>
    </AuthContextProvider>
  );
}

export default App;