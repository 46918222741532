function MenuBar() {
  return (
    <div>
      <div className="flex px-4 rounded-full lg:py-3 py-2 items-center lg:space-x-5 space-x-3 border-2 border-gray-100 flex-row justify-center">
        <div className="hover:cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default MenuBar;
