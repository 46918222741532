import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { addMonths, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { QRCodeSVG } from "qrcode.react";
import { addDays } from "date-fns/fp";

const Subs = () => {
  const [fuelAmount, setFuelAmount] = useState(0);
  const [voucherCode, setVoucherCode] = useState("");
  const [paybackAmount, setPaybackAmount] = useState(null);
  const [interestRate, setInterestRate] = useState(null);
  const [generated, setGenerated] = useState(false);
  const [paybackDate, setPaybackDate] = useState(null);
  const [firstIncrementDone, setFirstIncrementDone] = useState(false);
  const [selectedDate, setSelectedDate] = useState(true);

  const generateVoucherCode = () => {
    const characters = "0123456789";
    let code = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }

    setVoucherCode(code);
    setGenerated(true);

    // Calculate payback amount based on different interest rates
    const fuelAmountNumeric = parseFloat(fuelAmount);

    // Set different interest rates based on the duration
    let interestRateValue = 0.2; // Default interest rate

    if (selectedDate) {
      const currentDate = new Date();
      const daysUntilPayback = Math.ceil(
        (selectedDate - currentDate) / (1000 * 60 * 60 * 24)
      );

      if (daysUntilPayback <= 30) {
        interestRateValue = 0.1; // 10% interest for payback within 30 days
      } else if (daysUntilPayback <= 60) {
        interestRateValue = 0.15; // 15% interest for payback within 60 days
      }
      // Add more conditions for different interest rates based on your requirements
    }

    setInterestRate((interestRateValue * 100).toFixed(2)); // Convert to percentage
    const calculatedPayback = fuelAmountNumeric * interestRateValue;
    setPaybackAmount((calculatedPayback + fuelAmountNumeric).toFixed(2));

    // Set payback date directly from selected date
    if (selectedDate) {
      setPaybackDate(format(selectedDate, "MMMM dd, yyyy"));
    }
  };
  const handleFuelAmountChange = (value) => {
    const inputAmount = parseFloat(value);

    if (!isNaN(inputAmount) && inputAmount >= 0 && inputAmount <= 1500) {
      // If the input is a valid number within the specified range
      setFuelAmount(inputAmount);
      setPaybackAmount(null);
      setInterestRate(null);
      setGenerated(false);
      setPaybackDate(null);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleGenerateClick = () => {
    generateVoucherCode();
  };

  const incrementFuelAmount = () => {
    if (fuelAmount === 0 && !firstIncrementDone) {
      setFuelAmount(250);
      setFirstIncrementDone(true);
    } else if (fuelAmount < 1500) {
      setFuelAmount((prevAmount) => prevAmount + 50);
    }
  };

  const decrementFuelAmount = () => {
    if (fuelAmount > 0) {
      setFuelAmount((prevAmount) => prevAmount - 50);
    }
  };

  const isButtonDisabled = !fuelAmount || !selectedDate;

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
      {!generated && (
        <>
          <h2 className="text-2xl font-semibold mb-4">
            Fuel Voucher Generation
          </h2>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="fuelAmount"
            >
              Amount (in kwacha):
            </label>
            <div className="flex">
              <input
                type="number"
                id="fuelAmount"
                className="w-full p-2 border border-gray-300 rounded-md"
                value={fuelAmount}
                onChange={(e) => handleFuelAmountChange(e.target.value)}
              />
              <div className="flex flex-col ml-2">
                <button
                  className="py-1 px-2 bg-blue-500 text-white rounded-md cursor-pointer"
                  onClick={incrementFuelAmount}
                >
                  +
                </button>
                <button
                  className="py-1 px-2 bg-red-500 text-white rounded-md cursor-pointer mt-1"
                  onClick={decrementFuelAmount}
                >
                  -
                </button>
              </div>
            </div>
          </div>
          <div className="w-full mb-5">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Payback Date:
            </label>
            <input
              className="w-full lg:w-[400px] p-2 border border-gray-300 rounded-md"
              onChange={(e) => handleDateChange(new Date(e.target.value))}
              defaultValue={format(addDays(7)(new Date()), "yyyy-MM-dd")}
              disabled
            />
          </div>
          <button
            className={`${
              isButtonDisabled
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            } text-white px-4 py-2 rounded-md`}
            onClick={handleGenerateClick}
            disabled={isButtonDisabled}
          >
            Generate Fuel Voucher
          </button>
        </>
      )}

      {voucherCode && (
        <div className="mt-2">
          <h2 className="text-2xl font-semibold mb-4">
            Success, transaction details:
          </h2>
          <p className="text-green-700 font-semibold">
            Generated Voucher Code:
          </p>
          <p className="text-gray-800 mb-3 text-[18px] font-semibold">
            {voucherCode}
          </p>
          <p className="text-green-700 font-semibold">Amount:</p>
          <p className="text-gray-800 mb-3  text-[18px] font-semibold">
            K{fuelAmount}
          </p>
          <p className="text-green-700 font-semibold">Interest Rate:</p>
          <p className="text-gray-800 mb-3  text-[18px] font-semibold">
            {interestRate}%
          </p>
          <p className="text-green-700 font-semibold">Payback Amount:</p>
          <p className="text-gray-800 mb-3  text-[18px] font-semibold">
            K{paybackAmount}
          </p>
          <p className="text-green-700 font-semibold">Payback Date:</p>
          <p className="text-gray-800  mb-3 text-[18px] font-semibold">
            {format(addDays(7)(new Date()), "yyyy-MM-dd")}
          </p>
          <p className="text-gray-800 mt-4 text-[13px]">
            <span className="font-bold">Note: </span> Code will expire in the
            next 24 hours. Present code to your nearest Dumpay partner gas
            station or let them scan the QR Code below
          </p>
          <p className="mt-4">
            <QRCodeSVG value={voucherCode} />
          </p>
        </div>
      )}
    </div>
  );
};

export default Subs;
