import { hero, hero2, heroMobile } from "../../assets/images";
import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { getAuth } from "firebase/auth";
import Login from "../login/Login";
import Modal from "../../components/Modal";
import { useToast } from "@chakra-ui/react";
import "./index.css";

const Hero = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const toast = useToast();
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  function handleClick() {
    const { currentUser } = getAuth();
    console.log(currentUser);
    if (currentUser) {
      navigate("/apply");
    } else {
      modalOpen ? close() : open();
    }
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (phoneNumber !== "") {
      try {
        setIsSubmitting(true);

        // Add the phone number to the "phoneNumbers" collection in Firestore
        const docRef = await addDoc(collection(db, "phoneNumbers"), {
          phoneNumber: phoneNumber,
        });

        console.log("Phone number added with ID: ", docRef.id);

        setPhoneNumber("");
        setIsSubmitSuccess(true);
      } catch (error) {
        console.error("Error adding phone number: ", error);
      } finally {
        setIsSubmitting(false);
        toast({
          description: "Signed In successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom",
        });
      }
    } else {
      toast({
        description: "enter a number",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const closeToast = () => {
    // Close the toast and reset the success state
    setIsSubmitSuccess(false);
  };

  return (
    <div>
      <div>
        <div className="flex flex-col">
          <div className="">
            <div>
              <div className="flex flex-col justify-center items-center text-center">
                <div>
                  <h1 className="text-[32px] lg:text-[80px] text-gray-900  font-bold">
                    Drive Now, Pay Later!
                  </h1>
                </div>

                <div>
                  <p className="text-[17px] lg:text-[19px] pt-3 font-normal text-gray-800">
                    Get fuel on credit with dumpe
                  </p>
                </div>
                <div className="">
                  <div className="pt-6 flex flex-row items-center">
                    <div>
                      <input
                        type="tel"
                        id="phone-number"
                        name="phone-number"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        pattern="[0-9]{10}"
                        required
                        placeholder="Enter mobile number..."
                        className="w-full px-3 py-4 border rounded"
                      />
                    </div>
                    <div>
                      {isSubmitting ? (
                        <button
                          onClick={handleFormSubmit}
                          className="lg:px-[40px] px-[40px] py-4 lg:py-4 rounded-r-lg bg-[#E9B218] hover:bg-green-500 font-bold text-white"
                        >
                          Submitting
                        </button>
                      ) : (
                        <button
                          onClick={handleFormSubmit}
                          className="lg:px-[40px] text-[13px] px-[10px] py-[19px] lg:py-4 rounded-r-lg bg-[#E9B218] hover:bg-green-500 font-bold text-white"
                        >
                          Notify me
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="basis-[70%]">
            <div className="lg:mt-0 mt-2 flex justify-center items-center">
              <img src={hero} className="w-full lg:w-[600px]" />
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        {" "}
        {modalOpen && (
          <Modal
            modalOpen={modalOpen}
            handleClose={close}
            text={
              <Login
                signUpHeaderText="Sign In/Up"
                signUpBodyText="The Zambian entreprenurial ecosystem - startups, founders, investors,.."
                close={close}
              />
            }
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Hero;
