import { useState } from "react";
import Navbar from "../../components/navbar";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const DriverRegistration = () => {
  const [nameFull, setNameFull] = useState("");
  const [nrcPassport, setNRCPassport] = useState("");
  const [address, setAddress] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [workplaceContact, setWorkplaceContact] = useState("");
  const [photo, setPhoto] = useState(null); // Assuming you want to store the file object
  const [nrcPhoto, setNRCPhoto] = useState(null);

  const navigate = useNavigate();
  const toast = useToast();

  function handleClick() {
    navigate("/");
    toast({
      description:
        "Application successfully submitted. We shall get in touch within 24hrs",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom",
    });
  }

  const handleNameChange = (e) => {
    setNameFull(e.target.value);
  };

  const handleNRCPassportChange = (e) => {
    setNRCPassport(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleWorkplaceChange = (e) => {
    setWorkplace(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleVehicleNumberChange = (e) => {
    setVehicleNumber(e.target.value);
  };

  const handleWorkplaceContactChange = (e) => {
    setWorkplaceContact(e.target.value);
  };

  const handlePhotoUpload = (e) => {
    // Assuming you want to store the file object
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };
  const handleNRCUpload = (e) => {
    // Assuming you want to store the file object
    const selectedFile = e.target.files[0];
    setNRCPhoto(selectedFile);
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="flex justify-center">
        <div className="font-bold text-[20px]">Fill out the following form</div>
      </div>
      <div className="px-2">
        <div className="  px-1">
          <div className="flex flex-col justify-start space-x-1">
            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  Full Name
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Your full name as on official documents
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  value={nameFull}
                  onChange={handleNameChange}
                  id="name"
                  name="name"
                  type="text"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                  placeholder="e.g John Banda"
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mb-2 mt-6 text-[15px] font-medium text-gray-700">
                  NRC/Passport No.
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Must be a valid Zambian ID
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  value={nrcPassport}
                  onChange={handleNRCPassportChange}
                  id="nrcPassport"
                  name="nrcPassport"
                  type="number"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                  placeholder=""
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  Address
                </h1>
                <p className="text-gray-400 text-[13px]">Your home address</p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  value={address}
                  onChange={handleAddressChange}
                  id="address"
                  name="address"
                  type="address"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                  placeholder=""
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  Workplace
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Name of place you currently work
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  value={workplace}
                  onChange={handleWorkplaceChange}
                  id="workplace"
                  name="workplace"
                  type="text"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                  placeholder=""
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  Contact no.
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Simply your phone number
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  id="phoneNumber"
                  name="phoneNumber"
                  type="number"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                  placeholder="09xxxxxxxx"
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  Vehicle Number Plate
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Your vehicle's number plate
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  value={vehicleNumber}
                  onChange={handleVehicleNumberChange}
                  id="vehicleNumber"
                  name="vehicleNumber"
                  type="text"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                  placeholder="e.g ABC 123"
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  Workplace Contact No.
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Contact number at your workplace
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  value={workplaceContact}
                  onChange={handleWorkplaceContactChange}
                  id="workplaceContact"
                  name="workplaceContact"
                  type="number"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                  placeholder="09xxxxxxxx"
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  Passport Size Photo
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Upload a passport-size photo
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  onChange={handlePhotoUpload}
                  id="photo"
                  name="photo"
                  type="file"
                  accept="image/*"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                />
              </div>
            </div>

            <div>
              <div>
                <h1 className="block mt-6 mb-2 text-[15px] font-medium text-gray-700">
                  NRC
                </h1>
                <p className="text-gray-400 text-[13px]">
                  Upload your NRC, back and front
                </p>
              </div>
              <div className="lg:w-[400px] pt-5 w-full text-[16px] pr-2">
                <input
                  onChange={handleNRCUpload}
                  id="photo"
                  name="photo"
                  type="file"
                  accept="image/*"
                  className="w-full py-3 text-[14px] px-5 placeholder-slate-400 border font-sans rounded-lg text-gray-900"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-3 mx-3">
        <button
          onClick={handleClick}
          type="submit"
          className="lg:px-[40px] w-full lg:w-[280px] text-[16px] px-[10px] py-4 lg:py-4 border-[#E9B218] border-2 rounded-lg bg-[#E9B218] hover:bg-[#f7c88f] font-bold text-white"
        >
          Send Application
        </button>
      </div>
    </div>
  );
};

export default DriverRegistration;
