import React from "react";
import Navbar from "../../components/navbar";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import Value from "./Value";

const Home = () => {
  return (
    <div>
      <div className="px-[10px] lg:px-[120px] ">
        <div>
          <Navbar />
        </div>
        <div className="pt-6 lg:pt-7">
          <Hero />
        </div>
        <div className="flex lg:hidden">
          <Value />
        </div>
      </div>
      <div className="pt-7 flex lg:hidden">
        <HowItWorks />
      </div>
    </div>
  );
};

export default Home;
