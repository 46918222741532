import { logo } from "../../assets/images";
import MenuBar from "../menubar";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@chakra-ui/avatar";
import { AnimatePresence } from "framer-motion";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { useAuth } from "../../services/context/AuthContext";
import Login from "../../pages/login/Login";
import Modal from "../Modal";

function Navbar() {
  const { logout, currentUser } = useAuth();
  const toast = useToast();
  const [modalOpen, setModalOpen] = useState(false);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const navigate = useNavigate();

  const signIn = () => {
    modalOpen ? close() : open();
  };

  async function handleLogout() {
    try {
      await logout();
      navigate("/");
      toast({
        description: "Logout successfull",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom",
      });
    } catch (error) {
      console.log("Logout error:", error);
      // Handle the error
    }
  }

  return (
    <div>
      <div className=" flex-row py-4 flex items-center">
        <div className="lg:basis-[50%] basis-[70%]">
          <div>
            <div className="flex flex-row items-center space-x-2">
              <div className="hover:cursor-pointer">
                <div className="flex flex-row items-center space-x-1">
                  <div>
                    <img
                      className="lg:h-[60px] lg:w-[60px] h-[60px] w-[60px]"
                      src={logo}
                    />
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="lg:basis-[50%] basis-[30%]">
          <div>
            <div className="flex justify-end">
              {" "}
              <div>
                {" "}
                <div>
                  <div>
                    {currentUser ? (
                      <div
                        onClick={handleLogout}
                        className="flex flex-row space-x-1"
                      >
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#5a5a5a"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                            />
                          </svg>
                        </div>
                        <div className="text-[14px] lg:text-[15px] text-gray-700">
                          Logout
                        </div>
                      </div>
                    ) : (
                      <div className="hidden">
                        <Link to="/driver-registration">
                          <div className="py-3  text-[#E9B218] lg:text-white hover:cursor-pointer md:text-[11px] text-[13px] lg:py-4 lg:px-7 sm:px-3 sm:py-4 font-semibold px-6 rounded-lg bg-transparent  lg:bg-[#E9B218] border-2 border-[#E9B218] hover:bg-gray-200 ">
                            Sign Up
                          </div>
                        </Link>
                        <div className="hidden">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="black"
                            className="w-9 h-9"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        {" "}
        {modalOpen && (
          <Modal
            modalOpen={modalOpen}
            handleClose={close}
            text={
              <Login
                signUpHeaderText="Sign In/Up"
                signUpBodyText="The Zambian entreprenurial ecosystem - startups, founders, investors,.."
                close={close}
              />
            }
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default Navbar;
