import { useState } from "react";
import { useToast, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { github, google, x } from "../../assets/images";
import { useAuth } from "../../services/context/AuthContext";

function Login({ signUpHeaderText, signUpBodyText, close }) {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Track loading state
  const [loading1, setLoading1] = useState(false); // Track loading state
  const [loading2, setLoading2] = useState(false); // Track loading state
  const [loading3, setLoading3] = useState(false); // Track loading state

  const {
    signInWithGoogle,
    signInWithFacebook,
    signInWithGithub,
    signInWithTwitter,
  } = useAuth();

  const handleLoginSuccess = () => {
    console.log("Login successful!");
    navigate("/apply");
    toast({
      description: "Signed In successfully",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "bottom",
    });
  };

  const handleLoginFailure = (error) => {
    console.log(error);
    toast({
      description: "Error, please try again or use another method",
      status: "error",
      duration: 4000,
      isClosable: true,
      position: "bottom",
      containerStyle: {
        width: "350px",
      },
    });
  };

  const handleGoogleSignIn = async () => {
    setLoading(true); // Set loading state to true
    try {
      const user = await signInWithGoogle();
      console.log(user);
      handleLoginSuccess(); // Pass the user object to createUser function
      close();
    } catch (error) {
      console.log(error);
      handleLoginFailure(error);
    } finally {
      setLoading(false);
      // Set loading state to false
    }
  };

  //const handleFacebookSignIn = () => {
  //setLoading1(true); // Set loading state to true
  //signInWithFacebook()
  //.then((user) => {
  //console.log(user);
  //handleLoginSuccess();
  //})
  //.catch((error) => {
  //console.log(error);
  //handleLoginFailure(error);
  //})
  //.finally(() => {
  //setLoading1(false); // Set loading state to false
  //});
  //};

  const handleGithubSignIn = () => {
    setLoading2(true); // Set loading state to true
    signInWithGithub()
      .then((user) => {
        console.log(user);
        handleLoginSuccess();
        close();
      })
      .catch((error) => {
        console.log(error);
        handleLoginFailure(error);
      })
      .finally(() => {
        setLoading2(false); // Set loading state to false
      });
  };

  const handleTwitterSignIn = () => {
    setLoading3(true); // Set loading state to true
    signInWithTwitter()
      .then((user) => {
        console.log(user);
        handleLoginSuccess();
        close();
      })
      .catch((error) => {
        console.log(error);
        handleLoginFailure(error);
      })
      .finally(() => {
        setLoading3(false); // Set loading state to false
      });
  };

  return (
    <div className="bg-white pt-10 pb-10 font-medium">
      <div>
        <div>
          <div className="flex justify-center items-center text-center">
            <div className="">
              <div
                className="flex  flex-col
           justify-center items-center"
              >
                <div>
                  <h1 className="text-[23px] lg:text-[25px] font-medium">
                    Sign In/up
                  </h1>

                  <p className="text-[13px] lg:text-[14px] pt-1 lg:pt-1 font-normal text-gray-600">
                    {" "}
                    We've got you covered
                  </p>
                </div>

                {/*Google sign in*/}
                <div>
                  <div>
                    <button onClick={handleGoogleSignIn} disabled={loading}>
                      <div className="flex items-center justify-center w-[300px]  mt-4 flex-row space-x-4 lg:w-[400px] border-gray-300 px-4 lg:px-5 py-2 rounded-lg border ">
                        <div>
                          {loading ? (
                            <div className="mr-2">loading...</div>
                          ) : (
                            <img
                              src={google}
                              className="h-[38px] w-[38px] rounded-lg"
                              alt="Logo"
                            />
                          )}
                        </div>
                        <div>
                          <p className="text-[14px] pr-5">
                            Continue with Google
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                <div>
                  <div className="hidden">
                    <button onClick={handleTwitterSignIn} disabled={loading3}>
                      <div className="flex items-center justify-center w-[300px]  mt-4 flex-row space-x-4 lg:w-[400px] border-gray-300 px-4 lg:px-5 py-2 rounded-lg border ">
                        <div>
                          {loading3 ? (
                            <div className="mr-2">loading...</div>
                          ) : (
                            <img
                              className="h-[35px] w-[35px] rounded-lg"
                              alt="Logo"
                            />
                          )}
                        </div>
                        <div>
                          <p className="text-[14px]">
                            Continue with X (Twitter)
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                {/*X sign in*/}
                <div>
                  <div className="hidden">
                    <button onClick={handleGithubSignIn} disabled={loading2}>
                      <div className="flex items-center justify-center w-[300px]  mt-4 flex-row space-x-4 lg:w-[400px] border-gray-300 px-4 lg:px-5 py-2 rounded-lg border ">
                        <div>
                          {loading2 ? (
                            <div className="mr-2">loading...</div>
                          ) : (
                            <img
                              className="h-[35px] w-[35px] rounded-lg"
                              alt="Logo"
                            />
                          )}
                        </div>
                        <div>
                          <p className="text-[14px] pr-5">
                            Continue with Github
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                {/*X sign in*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
