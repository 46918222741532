import React from "react";
import { hero2, hero3 } from "../../assets/images";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <div className="lg:mx-[40px] flex flex-col justify-center items-center text-center lg:hidden md:mx-[25px] mx-[7px]">
      <div>
        <h1 className="text-[30px] text-gray-800 lg:text-[80px] font-bold">
          How it works?
        </h1>
      </div>
      <div className="pt-8">
        <div className="flex flex-col  space-x-3 items-center">
          <div>
            <div className="flex justify-center rounded-full items-center py-4 px-4 bg-[#E9B218]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="white"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                />
              </svg>
            </div>
          </div>
          <div className=" font-bold  pt-3">
            <h1 className="text-[17px]">Apply Online</h1>
            <p className="text-[16px] px-3 lg:text-[19px] pt-3 font-normal text-gray-800 ">
              {" "}
              Apply for the service on our website. It takes approximately 5
              minutes.
            </p>
          </div>
        </div>
      </div>

      <div className="pt-8">
        <div className="flex flex-col space-x-3 items-center">
          <div>
            <div className="flex justify-center rounded-full items-center py-4 px-4 bg-[#E9B218]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="white"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </div>
          <div className=" font-bold  pt-3">
            <h1 className="text-[17px]">Approval</h1>
            <p className="text-[16px] px-3 lg:text-[19px] pt-3 font-normal text-gray-800 ">
              {" "}
              Once your application is reviewed and approved, you will receive
              an email notification on how to install the app for generating
              fuel vouchers on credit
            </p>
          </div>
        </div>
      </div>

      <div className="pt-8">
        <div className="flex flex-col space-x-3 items-center">
          <div>
            <div className="flex justify-center rounded-full items-center py-4 px-4 bg-[#E9B218]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>
            </div>
          </div>
          <div className=" font-bold  pt-3">
            <h1 className="text-[17px]">Generate fuel vouchers</h1>
            <p className="text-[16px] px-3 lg:text-[19px] pt-3 font-normal text-gray-800 ">
              {" "}
              Use the mobile app to generate fuel vouchers of up to K1500, based
              on your assigned creditworthiness level
            </p>
          </div>
        </div>
      </div>

      <div className="pt-8">
        <div className="flex flex-col space-x-3 items-center">
          <div>
            <div className="flex justify-center rounded-full items-center py-4 px-4 bg-[#E9B218]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="white"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
                />
              </svg>
            </div>
          </div>
          <div className=" font-bold  pt-3">
            <h1 className="text-[17px]">Visit a Partner Gas Station</h1>
            <p className="text-[16px] px-3 lg:text-[19px] pt-3 font-normal text-gray-800 ">
              Redeem your fuel voucher at any partner gas station highlighted on
              the live map
            </p>
          </div>
        </div>
      </div>

      <div className="pt-8">
        <div className="flex flex-col space-x-3 items-center">
          <div>
            <div className="flex justify-center rounded-full items-center py-4 px-4 bg-[#E9B218]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="white"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                />
              </svg>
            </div>
          </div>
          <div className=" font-bold  pt-3">
            <h1 className="text-[17px]">Fuel Up</h1>
            <p className="text-[16px] px-3 lg:text-[19px] pt-3 font-normal text-gray-800 ">
              Present your voucher code to the attendant and fill up your tank,
              hussle-free.
            </p>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <h1 className="text-[30px] text-gray-800 lg:text-[80px] font-bold">
          Ready?
        </h1>
      </div>
      <div className="pt-4 pb-4">
        <div className="justify-center hidden items-center pt-4 ">
          <div>
            <Link to="/driver-registration">
              <div className="py-5 flex justify-center items-center lg:w-[150px] hover:cursor-pointer md:text-[14px] text-[13px] w-[150px] lg:py-4 lg:px-5 sm:px-3 sm:py-2 font-medium px-4  text-white rounded-full bg-[#E9B218] hover:bg-gray-300 ">
                <div>
                  <div className="flex flex-row items-center space-x-1">
                    <div>Apply Now</div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.8}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
