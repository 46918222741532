import React from "react";
import { hero, hero2, hero3 } from "../../assets/images";
import { Link } from "react-router-dom";

const Value = () => {
  return (
    <div>
      <div>
        <div className="flex flex-col lg:flex-row">
          <div className="">
            <div>
              <div className="flex flex-col justify-center lg:justify-start lg:text-start lg:items-start items-center text-center">
                <div>
                  <h1 className="text-[25px] pt-7 lg:text-[40px] text-gray-900  font-bold">
                    Embark on Stress-Free{" "}
                    <span className="text-[#E9B218]">Journeys</span>
                  </h1>
                </div>

                <div>
                  <p className="text-[16px] px-3 lg:text-[19px] pt-3 font-normal text-gray-800 ">
                    Life's uncertainties can sometimes leave you stranded,
                    especially when it comes to keeping your vehicle fueled and
                    always on the road.
                  </p>
                </div>
                <div>
                  <Link>
                    <div className="flex text-[#E9B218] underline flex-row items-center">
                      <div>
                        <span className="text-[13px] lg:text-[19px] font-normal">
                          Register for the dumpayy service
                        </span>
                      </div>
                      <div className="pt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </div>
                    </div>
                  </Link>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="basis-[70%]">
            <div className="lg:mt-0 mt-2 flex justify-center items-center">
              <img src={hero3} className="w-[250px] lg:w-[600px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Value;
